import { mdiCarConnected, mdiEye, mdiCarSeatHeater, mdiCarSeat, mdiCloud, mdiCarWindshield, mdiTelevision, mdiWifi, mdiUsbPort, mdiLockOpen, mdiCarCruiseControl, mdiHome, mdiSpeaker, mdiBluetooth } from '@mdi/js'

const Suspension = 'M15.072 15.216l-6-.96c-.264-.048-.504.144-.552.408-.048.264 .144.504 .408.552l6 .96c.024 0 .048 0 .072 0 .24 0 .432-.168.48-.408C15.504 15.504 15.336 15.264 15.072 15.216zM15.072 13.2l-6-.96c-.264-.048-.504.144-.552.408-.048.264 .144.504 .408.552l6 .96c.024 0 .048 0 .072 0 .24 0 .432-.168.48-.408C15.504 13.488 15.336 13.248 15.072 13.2zM15.072 11.184l-6-.96c-.264-.048-.504.144-.552.408-.048.264 .144.504 .408.552l6 .96c.024 0 .048 0 .072 0 .24 0 .432-.168.48-.408C15.504 11.472 15.336 11.208 15.072 11.184zM15.072 9.144l-6-.96c-.264-.048-.504.144-.552.408-.048.264 .144.504 .408.552l6 .96c.024 0 .048 0 .072 0 .24 0 .432-.168.48-.408C15.504 9.432 15.336 9.192 15.072 9.144zM8.928 7.128l6 .96c.024 0 .048 0 .072 0 .24 0 .432-.168.48-.408.048-.264-.144-.504-.408-.552l-6-.96c-.264-.048-.504.144-.552.408C8.496 6.84 8.664 7.08 8.928 7.128zM14.472 17.016l-4.536-.72c-.432-.072-.816.264-.816.72v1.2c0 .192.048 .288.264 .288h1.224c-.096.24-.168.48-.168.744 0 .912.744 1.68 1.656 1.68s1.656-.768 1.656-1.68c0-.264-.048-.48-.168-.72h1.032c.192 0 .456-.096.456-.288v-.456C15.12 17.352 14.808 17.064 14.472 17.016zM12.096 19.944c-.384 0-.696-.312-.696-.696s.312-.696.696-.696.696 .312.696 .696S12.504 19.944 12.096 19.944zM9.984 5.64l3.84.528c.432.048 .816-.288.816-.72v-1.944c0-.192-.168-.384-.36-.384h-.72c-.192 0-.36.168-.36.36S13.032 3.84 12.84 3.84h-1.68c-.192 0-.36-.168-.36-.36S10.632 3.12 10.44 3.12h-.72c-.192 0-.36.192-.36.384v1.416C9.36 5.28 9.624 5.592 9.984 5.64z'

export default {
  'car-connected': mdiCarConnected,
  'eye': mdiEye,
  'car-seat-heater': mdiCarSeatHeater,
  'car-seat': mdiCarSeat,
  'cloud': mdiCloud,
  'car-windshield': mdiCarWindshield,
  'television': mdiTelevision,
  'wifi': mdiWifi,
  'usb-port': mdiUsbPort,
  'lock-open': mdiLockOpen,
  'car-cruise-control': mdiCarCruiseControl,
  'home': mdiHome,
  'suspension': Suspension,
  'speaker': mdiSpeaker,
  'bluetooth': mdiBluetooth
}
